import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

let calendar;

document.addEventListener("turbolinks:load", () => {
  var $calendar = document.getElementById('calendar');
  if ($calendar === null) return;
  calendar = new Calendar($calendar, {
    plugins: [ dayGridPlugin ],
    timeZone: 'America/Chicago',
    events: '/events.json'
  });
  calendar.render();
});

document.addEventListener("turbolinks:before-cache", () => {
  if (calendar) {
    calendar.destroy();
  }
});
